import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { NavLink } from "react-router-dom";

const ProgramHomeModal = () => {

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1, // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1, // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1, // optional, default to 1.
        },
      };

    const carouselItems = [
      {
        imgSrc: "/images/IMG-20241209-WA0001.jpg",
        link: "/study-in-lithuania/study-program",
        buttonText: "Apply Now",
      },
      {
        imgSrc: "/images/IMG-20241209-WA0002.jpg",
        link: "/study-in-lithuania/study-program",
        buttonText: "Apply Now",
      },
      {
        imgSrc: "/images/IMG-20241209-WA0003.jpg",
        link: "/study-in-lithuania/study-program",
        buttonText: "Apply Now",
      },
      {
        imgSrc: "/images/IMG-20241209-WA0004.jpg",
        link: "/study-in-lithuania/study-program",
        buttonText: "Apply Now",
      },
      {
        imgSrc: "/images/IMG-20241209-WA0005.jpg",
        link: "/study-in-lithuania/study-program",
        buttonText: "Apply Now",
      },
      {
        imgSrc: "/images/IMG-20241209-WA0006.jpg",
        link: "/study-in-lithuania/study-program",
        buttonText: "Apply Now",
      },
      {
        imgSrc: "/images/IMG-20241209-WA0007.jpg",
        link: "/study-in-lithuania/study-program",
        buttonText: "Apply Now",
      },


        // {
        //   imgSrc: "/images/AfriProEdu - Masters Programs.jpg",
        //   link: "/list-of-courses",
        //   buttonText: "Apply Now",
        // },
        // {
        //   imgSrc: "/images/AfriProEdu - BSc Programs.jpg",
        //   link: "/list-of-courses",
        //   buttonText: "Apply Now",
        // },
        // {
        //   imgSrc: "/images/AfriProEdu - Notif 02.jpg",
        //   link: "/easygoedu-with-afriproedu",
        //   buttonText: "Apply Now",
        // },
      ];
  return (
    <div>
        <div className="md:max-w-lg max-w-[300px] w-full  body-font font-poppins">
          <div className="">
            <h2 className="text-center text-[#1DB459] font-bold md:text-[20px] text-[18px] leading-8">
              Discover Your Path to Lithuania!
            </h2>
            <Carousel
                // ref={carouselRefTwo}
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={true} // render carousel on server-side.
                infinite={true}
                rtl={false}
                // autoPlay={false} // Disable autoplay to prevent conflict with manual navigation
                // autoPlaySpeed={3000} // Optional: You can remove this if autoplay is disabled
                // keyBoardControl={true}
                transitionDuration={500} // Set transition to 500ms for smoother experience
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                className="rounded-[7px]"
                // arrows={false} // Hide default arrows
              >
              {carouselItems.map((item, index) => (
                <div key={index}>
                  <div className=" w-full">
                    <img src={item.imgSrc} className='md:w-[500px] md:h-[500px]' alt="hero" />
                  </div>
                  <div className="flex justify-between gap-3 mt-3">
                    <NavLink
                      to={item.link}
                      className="text-white w-full bg-[#1C8B48] rounded-[5px] flex justify-center font-medium text-sm md:px-5 md:py-3 py-2.5 mb-2"
                    >
                      {item.buttonText}
                    </NavLink>

                    <a
                      href="https://wa.me/2349047248430?text=Hello%20I%20would%20like%20to%20speak%20to%20a%20consultant"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="text-[#48B774] w-full flex justify-center bg-[#D7F5DC] rounded-[5px] font-medium text-sm md:px-5 md:py-3 py-2.5 mb-2"
                    >
                      Chat with Us
                    </a>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
    </div>
  )
}

export default ProgramHomeModal